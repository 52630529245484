import Helmet from "react-helmet"
import React from "react"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

export default function ThankYou() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
     * {
       font-family: 'Roboto', sans-serif !important;
     }
     header {
       padding: 20px;
       0;
       background: #0f26b0;
       display: flex;
       justify-content: center;
       align-items: center;
     }
     header img {
       height: 50px;
     }
     section {
       position: relative;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       height: calc(100vh - 90px);
       width: 100%;
       background: #fff;
     }
     .mlLogo {
       position: relative;
       display: block;
       width: 250px;
       padding: 0 0 44px 0;
       margin: 0 auto;
     }
     h1 {
       padding: 40px;
       font-weight: 400;
       font-size: 55px !important;
       color: #0319ac !important;
       text-align:center;
     }
     footer {
      font-family: 'Roboto', sans-serif;
      position:absolute;
      width:100%;
      background:#fff;
      bottom:0;
      padding:12px 0 0;
      }
    
      footer a {
      color:#333;
      }
      
      footer ul {
      margin:0 auto;
      text-align: center;
      display:flex;
      justify-content: center;
      align-items: center;
      color:#333;
      font-size:13px;
      padding:0 0 12px 0;
      list-style:none;
      }
      
      footer ul li {
      padding:0 6px 0 0;
      }
      
      footer ul li:last-child {
      padding:0;
      }
      
      footer ul li:after {
      content: '|';
      padding:0 0 0 6px;
      }
      
      footer ul li:last-child:after {
      content:none;
      padding:0;
      }
     @keyframes zoom-in-zoom-out {
       0% {
         transform: scale(1, 1);
       }
       25% {
         transform: scale(1, 1);
       }
       50% {
         transform: scale(1.25, 1.25);
       }
       75% {
         transform: scale(1, 1);
       }
       100% {
         transform: scale(1, 1);
       }
     }
     @media only screen and (max-width: 992px) {
      h1 {
        padding: 12px;
        font-weight: 400;
        font-size: 44px !important;
        color: #0319ac !important;
        text-align:center;
      }
      footer ul {
      flex-wrap:wrap;
    }
     }
      }
    `}
    </style>
  
    <title>Thank you for downloading</title>
    </Helmet>
    <header>
      <img class="topLogo" src="/img/mainlogo.png" alt="Manuals Library Logo" />
      </header>
    <section>
    <h1>Thank you for installing</h1>
    <img class="mlLogo" src="/img/logo.png" alt="Manuals Library Logo" />
    </section>
    <RawFooter></RawFooter>
    </>
  )
}
